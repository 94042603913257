import DashboardLayout from 'src/layout/DashboardLayout.vue'
// GeneralViews
import NotFound from 'src/pages/NotFoundPage.vue'
import CandidateFormNotFound from 'src/pages/Candidates/CandidateFormNotFoundPage.vue'
import CandidateFormSubmitted from 'src/pages/Candidates/CandidateFormSubmittedPage.vue'
import CandidateFormMeetingConfirmed from 'src/pages/Candidates/CandidateFormMeetingConfirmedPage.vue'
import CandidateFormDeclined from '../pages/Candidates/CandidateFormDeclinedPage.vue'

// Admin pages
import DeliveryAgentPage from 'src/pages/DeliveryAgentPage.vue'
import POPage from 'src/pages/POPage.vue'
import PesquisaPage from 'src/pages/PesquisaPage.vue'
import ZonasPage from 'src/pages/ZonasPage.vue'
import LoginPage from 'src/pages/LoginPage.vue'
import ActionsPage from 'src/pages/ActionsPage.vue'
import AdminUsersPage from 'src/pages/AdminUsersPage.vue'
import SchedulePage from 'src/pages/CalendarWeekPage.vue'
import DAPerformancePage from 'src/pages/DAPerformancePage.vue'
import CandidatesFormPage from 'src/pages/Candidates/CandidatesFormPage.vue'
import NewCandidate from 'src/pages/Candidates/NewCandidate.vue'
import CandidatesPage from 'src/pages/Candidates/CandidatesPage.vue'
import CandidatesManagementDocs from 'src/pages/Candidates/CandidatesManagementDocsPage.vue'
import ConfigurationPage from 'src/pages/ConfigurationPage.vue'
import CandidateFormMeetingConfirmationPage from 'src/pages/Candidates/CandidateFormMeetingConfirmationPage.vue'
import CandidateFormDecliningPage from '../pages/Candidates/CandidateFormDecliningPage.vue'

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/login'
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage
  },
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/login',
    children: [
      {
        path: 'config',
        name: 'Configuration',
        component: ConfigurationPage,
        meta: { authorize: 'Pages.Actions' }
      },
      {
        path: 'estafetas',
        name: 'Estafetas',
        component: DeliveryAgentPage,
        meta: { authorize: 'Pages.DAs' }
      },
      {
        path: 'pedidosAtivos',
        name: 'Pedidos Ativos',
        component: POPage,
        meta: { authorize: 'Pages.POs' }
      },
      {
        path: 'pesquisa',
        name: 'Pesquisa',
        component: PesquisaPage
      },
      {
        path: 'pesquisa/:ponum',
        name: 'PesquisaByPO',
        component: PesquisaPage
      },
      {
        path: 'zonas',
        name: 'Zonas Estafeta',
        component: ZonasPage,
        meta: { authorize: 'Pages.Zones' }
      },
      {
        path: 'acoes',
        name: 'Acoes',
        component: ActionsPage,
        meta: { authorize: 'Pages.Actions' }
      },
      {
        path: 'adminUsers',
        name: 'Administração Utilizadores',
        component: AdminUsersPage,
        meta: { authorize: 'Pages.Actions' }
      },
      {
        path: 'turnos',
        name: 'Turnos',
        component: SchedulePage,
        meta: { authorize: 'Pages.DAs.Schedule' }
      },
      {
        path: 'daPerformance',
        name: 'DAPerformance',
        component: DAPerformancePage,
        meta: { authorize: 'Pages.DAs.Performance' }
      },
      {
        path: "candidates",
        name: "Candidates",
        component: CandidatesPage,
        meta: { authorize: 'Pages.Candidates' }
      },
      {
        path: "newCandidate",
        name: "NewCandidate",
        component: NewCandidate,
        meta: { authorize: 'Pages.Candidates.NewCandidate' }
      },
      {
        path: "candidatesManagementDocs",
        name: "CandidatesManagementDocs",
        component: CandidatesManagementDocs,
        meta: { authorize: 'Pages.Candidates.ManagementDocs' }
      },
    ]
  },
  { path: '/candidates/form/formSubmitted', component: CandidateFormSubmitted },
  { path: '/candidates/form/formNotFound', component: CandidateFormNotFound },
  { path: '/candidates/form/mettingConfirmed', component: CandidateFormMeetingConfirmed },
  { path: '/candidates/form/declined', component: CandidateFormDeclined },
  {
    path: "/candidates/form/:token",
    name: "candidatesForm",
    component: CandidatesFormPage
  },
  {
    path: "/candidates/form/:token/meetingConfirmation",
    name: "candidateFormMeetingConfirmationPage",
    component: CandidateFormMeetingConfirmationPage
  },
  {
    path: "/candidates/form/:token/decline",
    name: "candidateFormDecliningPage",
    component: CandidateFormDecliningPage
  },
  { path: '*', component: NotFound }
]


export default routes


