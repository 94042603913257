
import http from "../http-common-oc";

class CandidatesOCDataService {
  GetCandidateById(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Candidate/GetCandidateById", config);
  };
  GetCandidateByToken(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Candidate/GetCandidateByToken", config);
  };
  GetAllCandidates(params, headers) {
    let config = {
      headers: headers
    }
    return http.get("/services/app/Candidate/GetAll", config);
  };
  GetCandidatesByZones(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Candidate/GetCandidatesByCurrentUserZonesPaginated", config);
  };
  GetCandidatesByZonesAndSearch(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/Candidate/GetCandidatesByCurrentUserZonesAndSearchPaginated", config);
  };
  SaveCandidate(candidate, headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/Candidate/SaveCandidate", candidate, config);
  };
  SaveCandidateObs(candidate, headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/Candidate/SaveCandidateObs", candidate, config);
  };
  ExtendExpirationDate(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Candidate/ExtendExpirationDate", {}, config);
  };
  SubmitCandidate(candidate, headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/Candidate/SubmitCandidate", candidate, config);
  };
  ExpireCandidate(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Candidate/ExpireCandidate", {}, config);
  };
  AskForAditionalInformation(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Candidate/AskForAditionalInformation", {}, config);
  };
  AcceptCandidate(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Candidate/AcceptCandidate", {}, config);
  };
  RejectCandidate(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Candidate/RejectCandidate", {}, config);
  };
  ArquiveCandidate(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Candidate/ArquiveCandidate", {}, config);
  };
  ConfirmMeeting(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Candidate/ConfirmMeetingCandidate", {}, config);
  };
  DeclineByCandidate(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/Candidate/DeclineByCandidate", {}, config);
  };
  SendNewCandidateInfoEmail(body, params, headers) {
    let config = {
      params: params,
      headers: headers
    }
    return http.post("/services/app/Candidate/SendNewCandidateInfoEmail", body, config);
  };
  UploadFiles(payload, headers) {
    let config = {
      headers: headers
    }
    return http.post("/services/app/CandidateDocs/UploadFiles", payload, config);
  };
  DownloadFile(params, headers) {
    let config = {
      headers: headers,
      params: params,
      responseType: 'arraybuffer'
    }
    return http.post("/services/app/CandidateDocs/DownloadFile", {}, config);
  };
  DeleteCandidateDoc(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.delete("/services/app/CandidateDocs/Delete", config);
  };
  GetCandidateDocs(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.get("/services/app/CandidateDocs/GetFilesFromCandidate", config);
  };

  StartCandidateExpireDateJob(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/StartCandidateExpireDateJob", {}, config);
  };
  StartCheckDANotOnlineJobShift0(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/StartCheckDANotOnlineJobShift0", {}, config);
  };
  StartCheckDANotOnlineJobShift1(params, headers) {
    let config = {
      headers: headers,
      params: params
    }
    return http.post("/services/app/LogAcao/StartCheckDANotOnlineJobShift1", {}, config);
  };
}

export default new CandidatesOCDataService();
