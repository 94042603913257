<template>
  <div v-if="daId != '' && daScheduleShift != '' && daScheduleShift.observations?.length > 1">
    <i class="fa fa-info" style="font-size: 15px; border: 1px solid black;border-radius: 45px;" :title="daScheduleShift.observations"></i>
  </div>
</template>
<script>
import DAScheduleDataService from "src/services/DAScheduleDataService"
export default {
  name: "DAShiftObservations",

  props: {
    daId: {
      type: Number,
      required: true
    },
  },
  mounted () {
    this.getDAScheduleShift(this.daId)
  },
  watch: {
    daId(newVal, oldVal) {
      this.daScheduleShift = ''
      this.getDAScheduleShift(this.daId)
    },
  },
  data() {
    return {
      daScheduleShift: ''
    }
  },

  methods:{
    getDAScheduleShift(daId){
      DAScheduleDataService.GetDAScheduleShift({daId: daId}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => this.daScheduleShift = response.data.result)
        .catch(error => {
          console.log(error)
        })
        .finally()
    }
  }
};
</script>

<style scoped>
</style>
