<template>
<div>
  <div class="text-center" v-if="loading">
    <b-spinner style="width: 10rem; height: 10rem; margin:20px" label="Large Spinner" variant="warning"></b-spinner>
  </div>
  <div class="page" v-if="!loading">
    <div style="float: right;width: 100%;">
      <p style="font-size: 12px;float: right;" v-if="new Date(candidate.tokenExpirationDate) > new Date()">O Formulário<span v-if="!isFormPage"> do candidato</span> expira a {{ this.getDateWithHoursFormated(candidate.tokenExpirationDate) }}</p>
      <p style="font-size: 12px;float: right;" v-else>O Formulário do candidato expirou a {{ this.getDateWithHoursFormated(candidate.tokenExpirationDate) }}</p>
    </div>
    <div v-if="candidate.status == 'ADITIONALINFOPENDING'">
      <b-row style="border: 3px solid #DAA520;border-style: none none solid none;">
        <b-col>
          <h4 style="color:#DAA520; margin-top: 0">Pedido de Informação</h4>
        </b-col>
      </b-row>
      <b-row  style="border: 1px solid #DAA520;border-radius: 0px 0px 8px 8px;">
        <b-col lg="2">
          <i class="fa fa-exclamation-triangle" style="color: #DAA520; font-size: 1.8em;padding-top:5px;padding-left:25px"></i>
        </b-col>
        <b-col lg="10">
          <p v-html="candidate.aditionalInfoObservations" style="white-space: pre-wrap;color: black;font-size: 12px;padding: 5px"></p>
        </b-col>
      </b-row>
    </div>
    <div style="margin: auto;width: 100%;padding:10px">
      <b-row style="border: 3px solid #DAA520;border-style: none none solid none;">
        <b-col lg="8">
          <h4 style="color:#DAA520; margin-top: 0">Informação Pessoal</h4>
        </b-col>
        <b-col lg="4" v-if="!isFormPage">
          <b-button size="sm" variant="secondary" :pressed="seeStatusHistory" pill style=" margin-left:20px;padding:5px;border-color: white;float:right" @click="statusHistoryModalInfo(candidateId)" title="Ver Histórico de Interações">
            <i class="fa fa-hourglass" style="color: #DAA520; font-size: 1.5em;"></i>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="8" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
          <b-row style="margin-left: 5px;color: gray">Nome Completo<p style="color: red;font-size: 16px">*</p></b-row>
          <b-form-input style="margin-bottom:15px"
            id="name-input"
            v-model="candidate.fullName"
            type="text"
          ></b-form-input>
        </b-col>
        <b-col lg="4" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
          <b-row style="margin-left: 5px;color: gray">Nacionalidade<p style="color: red;font-size: 16px">*</p></b-row>
          <b-form-select v-model="candidate.nationality" class="a" style="margin-bottom:15px">
            <b-form-select-option disabled value=""></b-form-select-option>
            <b-form-select-option value="Portuguesa">Portuguesa</b-form-select-option>
            <b-form-select-option value="Brasileira">Brasileira</b-form-select-option>
            <b-form-select-option value="Outra">Outra</b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
          <b-row style="margin-left: 5px;color: gray">Morada<p style="color: red;font-size: 16px">*</p><p style="font-size: 8px;margin-top: auto;padding-bottom: 5px;"> (Morada + Numero da porta + Código de Postal + Localidade)</p></b-row>
          <b-form-input style="margin-bottom:15px"
            id="address-input"
            v-model="candidate.address"
            type="text"
          ></b-form-input>
        </b-col>
        <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;" v-if="!isFormPage && candidate.deliveryAgentZone">
          <b-row style="margin-left: 5px;color: gray">Zona</b-row>
          <b-form-input style="margin-bottom:15px"
            id="address-input"
            v-model="candidate.deliveryAgentZone.name"
            type="text"
            :disabled="true"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="2" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
          <b-row style="margin-left: 5px;color: gray">Telefone<p style="color: red;font-size: 16px">*</p></b-row>
          <b-form-input style="margin-bottom:15px"
            id="phone-input"
            v-model="candidate.phone"
            type="number"
          ></b-form-input>
        </b-col>
        <b-col lg="2" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
          <b-row style="margin-left: 5px;color: gray">Telefone Secundário</b-row>
          <b-form-input style="margin-bottom:15px"
            id="secphone-input"
            v-model="candidate.secondaryPhone"
            type="number"
          ></b-form-input>
        </b-col>
        <b-col lg="5" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
          <b-row style="margin-left: 5px;color: gray">Email<p style="color: red;font-size: 16px">*</p></b-row>
          <b-form-input style="margin-bottom:15px"
            id="email-input"
            v-model="candidate.email"
            type="text"
          ></b-form-input>
        </b-col>
        <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
          <b-row style="margin-left: 5px;color: gray">Veículo<p style="color: red;font-size: 16px">*</p></b-row>
          <b-form-select v-model="candidate.vehicle" class="a" style="margin-bottom:15px">
            <b-form-select-option disabled value=""></b-form-select-option>
            <b-form-select-option value="Carro">Carro</b-form-select-option>
            <b-form-select-option value="Mota">Mota</b-form-select-option>
            <b-form-select-option value="Carro/Mota">Carro/Mota</b-form-select-option>
            <b-form-select-option value="Mota/Carro">Mota/Carro</b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
          <b-row style="margin-left: 5px;color: gray">Documento de Identificação<p style="color: red;font-size: 16px">*</p></b-row>
          <b-form-select v-model="candidate.docType" class="a" style="margin-bottom:15px">
            <b-form-select-option disabled value=""></b-form-select-option>
            <b-form-select-option value="CC">Cartão de Cidadão</b-form-select-option>
            <b-form-select-option value="CR">Cartão de Residência</b-form-select-option>
            <b-form-select-option value="P">Passaporte</b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col lg="3" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
          <b-row style="margin-left: 5px;color: gray">Nº Documento<p style="color: red;font-size: 16px">*</p></b-row>
          <b-form-input style="margin-bottom:15px"
            id="docNum-input"
            v-model="candidate.docNum"
            type="text"
          ></b-form-input>
        </b-col>
        <b-col lg="4" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
          <b-row style="margin-left: 5px;color: gray">Validade Documento<p style="color: red;font-size: 16px">*</p></b-row>
          <b-row style="margin-bottom:15px">
            <b-col>
              <b-form-input
                id="docExpireDay-input"
                v-model="docExpireDay"
                type="number"
                min="1"
                max="31"
              ></b-form-input>
            </b-col>
            <span style="text-align:center; margin-top: 0; font-size: 30px;">/</span>
            <b-col>
              <b-form-input
                id="docExpireMon-input"
                v-model="docExpireMon"
                type="number"
                min="1"
                max="12"
              ></b-form-input>
            </b-col>
            <span style="text-align:center; margin-top: 0; font-size: 30px;">/</span>
            <b-col>
              <b-form-input
                id="docExpireYear-input"
                v-model="docExpireYear"
                type="number"
                :min="(new Date()).getFullYear()"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="2" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
          <b-row style="margin-left: 5px;color: gray">NIF<p style="color: red;font-size: 16px">*</p></b-row>
          <b-form-input style="margin-bottom:15px"
            id="nif-input"
            v-model="candidate.nif"
            type="number"
            :state="candidate.nif?.length == 9 ? null : false"
          ></b-form-input>
          <b-form-invalid-feedback id="nif-input-feedback">
            *9 digitos
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="8" class="my-1" style="border: 1px solid #DAA520;border-style: none none solid none;">
          <b-row style="margin-left: 5px;color: gray">IBAN</b-row>
          <b-form-input style="margin-bottom:15px"
            id="iban-input"
            v-model="candidate.iban"
            type="text"
            :state="(candidate.iban?.length == 25 || candidate.iban?.length == 0 || candidate.iban == null) ? null : false"
          ></b-form-input>
          <b-form-invalid-feedback id="nif-input-feedback">
            *25 caracteres
          </b-form-invalid-feedback>
        </b-col>
        <b-col lg="4" class="my-1" style="border: 1px solid #DAA520;border-style: none solid solid none;">
          <b-row style="margin-left: 5px;color: gray">Nome do Titular da Conta Bancária</b-row>
          <b-form-input style="margin-bottom:15px"
            id="bankAccName-input"
            v-model="candidate.bankAccountName"
            type="text"
          ></b-form-input>
        </b-col>
      </b-row>
    </div>
    <div style="margin: auto;width: 100%;padding:10px" v-if="this.candidateSchedule != null">
      <b-row style="border: 3px solid #DAA520;border-style: none none solid none;height:50px"><h4 style="color:#DAA520;margin-left: 10px; margin-top: 0;">Horário</h4></b-row>
      <div class="row justify-content-center" style="margin: auto;width: 90%;border: 1px solid #DAA520;border-style: none none solid none;">
        <b-col>
          <b-row style="border: 1px solid #DAA520;">
            <h5 style="text-align: center;font-weight:bold;width:100%;color:white">HORÁRIO</h5>
          </b-row>
          <b-row style="border: 1px solid #DAA520;height:79px">
            <h5 style="text-align: center;font-weight:bold;width:100%">ALMOÇO</h5>
          </b-row>
          <b-row style="border: 1px solid #DAA520;height:79px">
            <h5 style="text-align: center;font-weight:bold;width:100%">JANTAR</h5>
          </b-row>
        </b-col>
        <b-col v-for="day in scheduleHeader" :key="day.id">
          <b-row style="border: 1px solid #DAA520;">
            <h5 style="text-align: center;font-weight:bold;width:100%">{{day.header}}</h5>
          </b-row>
          <b-row style="border: 1px solid #DAA520;" :title="(candidateSchedule.filter(s => s.shift == 1 && s.dayOfWeek == day.id)[0]).observations">
            <b-form-select size="md" v-model="(candidateSchedule.filter(s => s.shift == 1 && s.dayOfWeek == day.id)[0]).state" :style="'background-color:'+((candidateSchedule.filter(s => s.shift == 1 && s.dayOfWeek == day.id)[0]).state == 'Summoned'? 'lightgreen':((candidateSchedule.filter(s => s.shift == 1 && s.dayOfWeek == day.id)[0]).state == 'Available'? 'lightblue':'gray')) +';cursor: pointer; color: black; font-size:10px;text-align:center'" :disabled="!editBool">
              <b-form-select-option value="Unavailable" style="background-color:gray">Indisponivel</b-form-select-option>
              <b-form-select-option value="Summoned" style="background-color:lightgreen">Convocado</b-form-select-option>
              <b-form-select-option value="Available" style="background-color:lightblue">Disponivel</b-form-select-option>
            </b-form-select>
            <b-form-textarea :disabled="!editBool"
              v-model="(candidateSchedule.filter(s => s.shift == 1 && s.dayOfWeek == day.id)[0]).observations"
              placeholder="Observações sobre o turno.."
              style="font-size: 10px"
            ></b-form-textarea>
          </b-row>
          <b-row style="border: 1px solid #DAA520;" :title="(candidateSchedule.filter(s => s.shift == 2 && s.dayOfWeek == day.id)[0]).observations">
            <b-form-select size="md" v-model="(candidateSchedule.filter(s => s.shift == 2 && s.dayOfWeek == day.id)[0]).state" :style="'background-color:'+((candidateSchedule.filter(s => s.shift == 2 && s.dayOfWeek == day.id)[0]).state == 'Summoned'? 'lightgreen':((candidateSchedule.filter(s => s.shift == 2 && s.dayOfWeek == day.id)[0]).state == 'Available'? 'lightblue':'gray')) +';cursor: pointer; color: black; font-size:10px;text-align:center'" :disabled="!editBool">
              <b-form-select-option value="Unavailable" style="background-color:gray">Indisponivel</b-form-select-option>
              <b-form-select-option value="Summoned" style="background-color:lightgreen">Convocado</b-form-select-option>
              <b-form-select-option value="Available" style="background-color:lightblue">Disponivel</b-form-select-option>
            </b-form-select>
            <b-form-textarea :disabled="!editBool"
              v-model="(candidateSchedule.filter(s => s.shift == 2 && s.dayOfWeek == day.id)[0]).observations"
              placeholder="Observações sobre o turno.."
              style="font-size: 10px"
            ></b-form-textarea>
          </b-row>
        </b-col>
        <b-button size="sm" variant="warning" style="float:right" @click="changeCandidateSchedule(candidateSchedule, candidateScheduleSaved, candidate.id)" v-if="JSON.stringify(this.candidateSchedule)!=JSON.stringify(this.candidateScheduleSaved) && editBool">Guardar Alterações</b-button>
        <b-button size="sm" variant="warning" style="float:right" @click="editBool = true" v-if="!editBool && !candidate.isArchived">Editar</b-button>
        <b-button size="sm" variant="secondary" style="float:right" @click="editBool = false" v-if="JSON.stringify(this.candidateSchedule)==JSON.stringify(this.candidateScheduleSaved) && editBool">Cancelar</b-button>
      </div>
    </div>
    <div style="margin: auto;width: 100%;padding:10px">
      <b-row style="border: 3px solid #DAA520;border-style: none none solid none;"><h4 style="color:#DAA520;margin-left: 10px; margin-top: 0">Documentos</h4></b-row>
      <div>
        <b-row style="border: 2px solid #DAA520;border-style: none none solid none;margin:10px">
          <b-col lg="3" style="border: 1px solid #DAA520;border-style: none solid solid solid;cursor: pointer;" @click="submitFilesInfo( candidate.id, 'CV', $event.target)">
            <b-row style="margin: 2px"><i class="fa fa-plus-square addFileIcon" title="Adicionar Ficheiro a Currículos"></i><strong style="font-size: 12px;">Curriculo</strong></b-row>
          </b-col>
          <b-col lg="9">
            <b-row v-for="doc in candidateFiles.cv" :key="doc.id" style="border-width: 1px; border-style: none solid solid none; border-color: rgb(218, 165, 32);">
              <b-col class="my-1" style="border: 1px solid #DAA520;border-style: none solid none none;">
                <b-row style="margin-left: 5px;padding:3px"><strong style="font-size: 12px;">{{getDateWithHoursFormated(doc.uploadDate)}}</strong></b-row>
              </b-col>
              <b-col class="my-1">
                <b-row style="margin-left: 5px;padding:3px" class="docsView" title="Visualizar Documento" @click="seeDocModalInfo(doc.id, doc.docExtension)"><i class="fa fa-file" style="font-size: 15px;"></i><strong class="textOverflow" style="font-size: 12px;">{{doc.docName}}</strong></b-row>
              </b-col>
              <i class="fa fa-trash removeFileIcon" title="Remover Ficheiro" @click="deleteCandidateFileInfo(doc)"></i>
              <i class="fa fa fa-download transferFileIcon" title="Tranferir Ficheiro" @click="downloadFiles(doc.id, doc.docName)"></i>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="border: 2px solid #DAA520;border-style: none none solid none;margin:10px">
          <b-col lg="3" style="border: 1px solid #DAA520;border-style: none solid solid solid;cursor: pointer;" @click="submitFilesInfo( candidate.id, 'IDDoc', $event.target)">
            <b-row style="margin: 2px"><i class="fa fa-plus-square addFileIcon" title="Adicionar Ficheiro a Documento de Identificação"></i><strong style="font-size: 12px;">Documento de Identificação</strong><p style="color: red;font-size: 16px">*</p></b-row>
          </b-col>
          <b-col lg="9">
            <b-row v-for="doc in candidateFiles.idDoc" :key="doc.id" style="border-width: 1px; border-style: none solid solid none; border-color: rgb(218, 165, 32);">
              <b-col class="my-1" style="border: 1px solid #DAA520;border-style: none solid none none;">
                <b-row style="margin-left: 5px;padding:3px"><strong style="font-size: 12px;">{{getDateWithHoursFormated(doc.uploadDate)}}</strong></b-row>
              </b-col>
              <b-col class="my-1">
                <b-row style="margin-left: 5px;padding:3px" class="docsView" title="Visualizar Documento" @click="seeDocModalInfo(doc.id, doc.docExtension)"><i class="fa fa-file" style="font-size: 15px;"></i><strong class="textOverflow" style="font-size: 12px;">{{doc.docName}}</strong></b-row>
              </b-col>
              <i class="fa fa-trash removeFileIcon" title="Remover Ficheiro" @click="deleteCandidateFileInfo(doc)"></i>
              <i class="fa fa fa-download transferFileIcon" title="Tranferir Ficheiro" @click="downloadFiles(doc.id, doc.docName)"></i>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="border: 2px solid #DAA520;border-style: none none solid none;margin:10px" v-if="candidate.docType == 'P'">
          <b-col lg="3" style="border: 1px solid #DAA520;border-style: none solid solid solid;cursor: pointer;" @click="submitFilesInfo( candidate.id, 'NIFProof', $event.target)">
            <b-row style="margin: 2px"><i class="fa fa-plus-square addFileIcon" title="Adicionar Ficheiro a Comprovativo de NIF"></i><strong style="font-size: 12px;">Comprovativo NIF</strong><p style="color: red;font-size: 16px">*</p></b-row>
          </b-col>
          <b-col lg="9">
            <b-row v-for="doc in candidateFiles.nifProof" :key="doc.id" style="border-width: 1px; border-style: none solid solid none; border-color: rgb(218, 165, 32);">
              <b-col class="my-1" style="border: 1px solid #DAA520;border-style: none solid none none;">
                <b-row style="margin-left: 5px;padding:3px"><strong style="font-size: 12px;">{{getDateWithHoursFormated(doc.uploadDate)}}</strong></b-row>
              </b-col>
              <b-col class="my-1">
                <b-row style="margin-left: 5px;padding:3px" class="docsView" title="Visualizar Documento" @click="seeDocModalInfo(doc.id)"><i class="fa fa-file" style="font-size: 15px;"></i><strong class="textOverflow" style="font-size: 12px;">{{doc.docName}}</strong></b-row>
              </b-col>
              <i class="fa fa-trash removeFileIcon" title="Remover Ficheiro" @click="deleteCandidateFileInfo(doc)"></i>
              <i class="fa fa fa-download transferFileIcon" title="Tranferir Ficheiro" @click="downloadFiles(doc.id, doc.docName)"></i>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="border: 2px solid #DAA520;border-style: none none solid none;margin:10px">
          <b-col lg="3" style="border: 1px solid #DAA520;border-style: none solid solid solid;cursor: pointer;" @click="submitFilesInfo( candidate.id, 'AddressProof', $event.target)">
            <b-row style="margin: 2px"><i class="fa fa-plus-square addFileIcon" title="Adicionar Ficheiro a Comprovativo de Morada"></i><strong style="font-size: 12px;">Comprovativo Morada</strong><p style="color: red;font-size: 16px">*</p></b-row>
          </b-col>
          <b-col lg="9">
            <b-row v-for="doc in candidateFiles.addressProof" :key="doc.id" style="border-width: 1px; border-style: none solid solid none; border-color: rgb(218, 165, 32);">
              <b-col class="my-1" style="border: 1px solid #DAA520;border-style: none solid none none;">
                <b-row style="margin-left: 5px;padding:3px"><strong style="font-size: 12px;">{{getDateWithHoursFormated(doc.uploadDate)}}</strong></b-row>
              </b-col>
              <b-col class="my-1">
                <b-row style="margin-left: 5px;padding:3px" class="docsView" title="Visualizar Documento" @click="seeDocModalInfo(doc.id, doc.docExtension)"><i class="fa fa-file" style="font-size: 15px;"></i><strong class="textOverflow" style="font-size: 12px;">{{doc.docName}}</strong></b-row>
              </b-col>
              <i class="fa fa-trash removeFileIcon" title="Remover Ficheiro" @click="deleteCandidateFileInfo(doc)"></i>
              <i class="fa fa fa-download transferFileIcon" title="Tranferir Ficheiro" @click="downloadFiles(doc.id, doc.docName)"></i>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="border: 2px solid #DAA520;border-style: none none solid none;margin:10px" v-if="candidate.iban != ''">
          <b-col lg="3" style="border: 1px solid #DAA520;border-style: none solid solid solid;cursor: pointer;" @click="submitFilesInfo( candidate.id, 'BankProof', $event.target)">
            <b-row style="margin: 2px"><i class="fa fa-plus-square addFileIcon" title="Adicionar Ficheiro a Comprovativo IBAN"></i><strong style="font-size: 12px;">Comprovativo IBAN</strong><p style="color: red;font-size: 16px">*</p></b-row>
          </b-col>
          <b-col lg="9">
            <b-row v-for="doc in candidateFiles.bankProof" :key="doc.id" style="border-width: 1px; border-style: none solid solid none; border-color: rgb(218, 165, 32);">
              <b-col class="my-1" style="border: 1px solid #DAA520;border-style: none solid none none;">
                <b-row style="margin-left: 5px;padding:3px"><strong style="font-size: 12px;">{{getDateWithHoursFormated(doc.uploadDate)}}</strong></b-row>
              </b-col>
              <b-col class="my-1">
                <b-row style="margin-left: 5px;padding:3px" class="docsView" title="Visualizar Documento" @click="seeDocModalInfo(doc.id, doc.docExtension)"><i class="fa fa-file" style="font-size: 15px;"></i><strong class="textOverflow" style="font-size: 12px;">{{doc.docName}}</strong></b-row>
              </b-col>
              <i class="fa fa-trash removeFileIcon" title="Remover Ficheiro" @click="deleteCandidateFileInfo(doc)"></i>
              <i class="fa fa fa-download transferFileIcon" title="Tranferir Ficheiro" @click="downloadFiles(doc.id, doc.docName)"></i>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="border: 2px solid #DAA520;border-style: none none solid none;margin:10px">
          <b-col lg="3" style="border: 1px solid #DAA520;border-style: none solid solid solid;cursor: pointer;" @click="submitFilesInfo( candidate.id, 'DriversIdentification', $event.target)">
            <b-row style="margin: 2px"><i class="fa fa-plus-square addFileIcon" title="Adicionar Ficheiro a Carta de Condução"></i><strong style="font-size: 12px;">Carta de Condução</strong><p style="color: red;font-size: 16px">*</p></b-row>
          </b-col>
          <b-col lg="9">
            <b-row v-for="doc in candidateFiles.driversIdentification" :key="doc.id" style="border-width: 1px; border-style: none solid solid none; border-color: rgb(218, 165, 32);">
              <b-col class="my-1" style="border: 1px solid #DAA520;border-style: none solid none none;">
                <b-row style="margin-left: 5px;padding:3px"><strong style="font-size: 12px;">{{getDateWithHoursFormated(doc.uploadDate)}}</strong></b-row>
              </b-col>
              <b-col class="my-1">
                <b-row style="margin-left: 5px;padding:3px" class="docsView" title="Visualizar Documento" @click="seeDocModalInfo(doc.id, doc.docExtension)"><i class="fa fa-file" style="font-size: 15px;"></i><strong class="textOverflow" style="font-size: 12px;">{{doc.docName}}</strong></b-row>
              </b-col>
              <i class="fa fa-trash removeFileIcon" title="Remover Ficheiro" @click="deleteCandidateFileInfo(doc)"></i>
              <i class="fa fa fa-download transferFileIcon" title="Tranferir Ficheiro" @click="downloadFiles(doc.id, doc.docName)"></i>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="border: 2px solid #DAA520;border-style: none none solid none;margin:10px">
          <b-col lg="3" style="border: 1px solid #DAA520;border-style: none solid solid solid;cursor: pointer;" @click="submitFilesInfo( candidate.id, 'VehicleInsurance', $event.target)">
            <b-row style="margin: 2px"><i class="fa fa-plus-square addFileIcon" title="Adicionar Ficheiro a Comprovativo Seguro da Viatura"></i><strong style="font-size: 12px;">Comprovativo Seguro Viatura</strong><p style="color: red;font-size: 16px">*</p></b-row>
          </b-col>
          <b-col lg="9">
            <b-row v-for="doc in candidateFiles.vehicleInsurance" :key="doc.id" style="border-width: 1px; border-style: none solid solid none; border-color: rgb(218, 165, 32);">
              <b-col class="my-1" style="border: 1px solid #DAA520;border-style: none solid none none;">
                <b-row style="margin-left: 5px;padding:3px"><strong style="font-size: 12px;">{{getDateWithHoursFormated(doc.uploadDate)}}</strong></b-row>
              </b-col>
              <b-col class="my-1">
                <b-row style="margin-left: 5px;padding:3px" class="docsView" title="Visualizar Documento" @click="seeDocModalInfo(doc.id, doc.docExtension)"><i class="fa fa-file" style="font-size: 15px;"></i><strong class="textOverflow" style="font-size: 12px;">{{doc.docName}}</strong></b-row>
              </b-col>
              <i class="fa fa-trash removeFileIcon" title="Remover Ficheiro" @click="deleteCandidateFileInfo(doc)"></i>
              <i class="fa fa fa-download transferFileIcon" title="Tranferir Ficheiro" @click="downloadFiles(doc.id, doc.docName)"></i>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
    <div style="margin: auto;width: 100%;padding:10px" v-if="!isFormPage">
      <b-row style="border: 3px solid #DAA520;border-style: none none solid none;"><h4 style="color:#DAA520;margin-left: 10px; margin-top: 0">Observações</h4></b-row>
      <b-form-textarea
        id="observation-input"
        v-model="candidate.observations"
        placeholder="Observações sobre o candidato.."
      ></b-form-textarea>
    </div>
    <p style="font-size: 10px;color: red;width: 90%;margin: auto;" v-if="isFormPage">*Campos Obrigatórios</p>
    <p style="font-size: 10px;width: 90%;margin: auto;" v-if="isFormPage">*Deverá preencher todos os campos e documentos obrigatórios para desbloquear o botão de envio para validação!</p>
    <b-button size="sm" variant="warning" style="float:right" @click="saveCandidate(candidate, docExpireDay, docExpireMon, docExpireYear)" v-if="isFormPage && (candidate.status == 'PENDING' || candidate.status == 'ADITIONALINFOPENDING')">Gravar Rascunho</b-button>
    <b-button size="sm" variant="warning" style="float:right" @click="saveCandidateObs(candidate, docExpireDay, docExpireMon, docExpireYear)" v-if="!isFormPage && candidate.status != 'DECLINED' && !candidate.isArchived">Gravar</b-button>
    <b-button size="sm" variant="info" style="float:right" @click="extendExpirationDate(candidate.id)" v-if="!isFormPage && (candidate.status == 'PENDING' || candidate.status == 'ADITIONALINFOPENDING' || candidate.status == 'EXPIRED')">Extender Acesso</b-button>
    <b-button size="sm" variant="secondary" style="float:right" @click="expireCandidate(candidate.id)" v-if="!isFormPage && (candidate.status == 'PENDING' || candidate.status == 'ADITIONALINFOPENDING')">Remover Acesso</b-button>
    <b-button size="sm" variant="warning" style="float:right" @click="moreInfoModalInfo(candidate.id)" v-if="!isFormPage && candidate.status == 'SUBMITED'">Pedido Adicional</b-button>
    <b-button size="sm" variant="success" style="float:right" @click="acceptCandidateInfo(candidate, docExpireDay, docExpireMon, docExpireYear)" v-if="!isFormPage && candidate.status == 'SUBMITED'">Aceitar</b-button>
    <b-button size="sm" variant="danger" style="float:right" @click="rejectCandidate(candidate.id)" v-if="!isFormPage && candidate.status == 'SUBMITED'">Rejeitar</b-button>
    <b-button size="sm" variant="danger" style="float:right" @click="arquiveCandidate(candidate.id)" v-if="!isFormPage && candidate.status == 'DECLINED' && !candidate.isArchived">Arquivar</b-button>
    <b-button size="sm" variant="info" style="float:right" @click="extendExpirationDate(candidate.id)" v-if="!isFormPage && candidate.status == 'DECLINED'">Reativar Formulário</b-button>
    <div style="float:right" v-if="isFormPage && (candidate.status == 'PENDING' || candidate.status == 'ADITIONALINFOPENDING')">
      <b-button size="sm" :disabled="true" variant="secondary" style="color: gray" v-if="candidate.fullName == '' || candidate.phone == '' || candidate.email == '' || candidate.address == '' || candidate.nationality == '' || candidate.vehicle == '' || candidate.docType == '' || candidate.docNum == '' || candidate.nif == '' || docExpireDay == '' || docExpireMon == '' || docExpireYear == '' || candidate.fullName == null || candidate.phone == null || candidate.email == null || candidate.address == null || candidate.nationality == null || candidate.vehicle == null || candidate.docType == null || candidate.docNum == null || candidate.nif == null || candidateFiles.idDoc?.length == 0 || candidateFiles.idDoc == null || ((candidateFiles.nifProof?.length == 0 || candidateFiles.nifProof == null) && candidate.docType == 'P') || candidateFiles.addressProof?.length == 0 || candidateFiles.addressProof == null || ((candidateFiles.bankProof?.length == 0 || candidateFiles.bankProof == null) && candidate.iban != '') || candidateFiles.driversIdentification?.length == 0 || candidateFiles.driversIdentification == null || candidateFiles.vehicleInsurance?.length == 0 || candidateFiles.vehicleInsurance == null || candidate.nif?.length != 9 || (candidate.iban?.length != 25 && candidate.iban?.length != 0 && candidate.iban != null)" @click="submitModalInfo()">Enviar para validação</b-button>
      <b-button size="sm" variant="success" v-else @click="submitModalInfo()" >Enviar para validação</b-button>
    </div>
  </div>
  <b-modal :id="submitFilesModal.id" :title="submitFilesModal.title"  :ok-disabled="files == ''"  ok-variant="success" ok-title="Guardar" cancel-title="Cancelar" @hide="resetSubmitFilesModal" @ok="submitFiles(submitFilesModal.candidateId, submitFilesModal.docType)">
    <div style="border: 1px solid gray;margin:10px; padding: 5px">
      <label>
        <input type="file" multiple @change="handleFileUploads($event)" style="font-size: 11px;"/>
      </label>
      <h4 style="font-size:10px; margin-top: 0">(tamanho max. 2MB)</h4>
      <div style="width:fit-content">
          <b-row v-for="(file, key) in files" :key="key" style="margin: 10px;border: 1px solid #DAA520;padding:5px;">
            <i class="fa fa-file" style="font-size: 15px;"></i><strong style="font-size: 12px;">{{ file.name }}</strong>
          </b-row>
      </div>
    </div>
  </b-modal>
  <b-modal :id="submitModal.id" modal-class="modalClass" scrollable size="xl" :ok-disabled="!isSigned" ok-variant="success" ok-title="Submeter" cancel-title="Cancelar" button-size="sm" @hide="resetSubmitModal" @ok="submitCandidate(candidate, docExpireDay, docExpireMon, docExpireYear)">
    <div>
      <div class="text-center" v-if="!pdfRendered">
        <b-spinner style="width: 10rem; height: 10rem; margin:20px" label="Large Spinner" variant="warning"></b-spinner>
      </div>
      <vue-pdf-embed :source="contractDoc" @rendered="pdfRendered = true"/>
      <b-form-checkbox v-model="isSigned" v-if="pdfRendered"><span style="color: red;">*</span>Li e concordo com os termos e condições</b-form-checkbox>
    </div>
  </b-modal>
  <b-modal :id="moreInfoModal.id" :title="moreInfoModal.title" :ok-disabled="moreInfoObservations == ''" ok-variant="success" ok-title="Enviar" cancel-title="Cancelar" @hide="resetMoreInfoModal" @ok="askForAditionalInformation(candidateId, moreInfoObservations)">
    <b-form-textarea
      id="observation-input"
      v-model="moreInfoObservations"
      placeholder="Pedido de informação.."
    ></b-form-textarea>
  </b-modal>
  <b-modal :id="deleteCandidateModal.id" :title="deleteCandidateModal.title" ok-variant="success" ok-title="Sim" cancel-variant="secondary" cancel-title="Não" @hide="resetDeleteCandidateModal" @ok="deleteCandidateFile(deleteCandidateModal.content.id, deleteCandidateModal.content.candidateId)">
  </b-modal>
  <b-modal :id="statusHistoryModal.id" :title="statusHistoryModal.title" size="lg" ok-variant="secondary" ok-title="Fechar" ok-only @hide="resetStatusHistoryModal" @ok="resetStatusHistoryModal">
    <div class="table-responsive">
      <div v-if="statusHistoryModal.content.length > 0">
        <b-table  class="tables"
          responsive
          striped
          bordered
          outlined
          hover
          small
          thead-class="cm-bg"
          sort-icon-left
          no-border-collapse :items="statusHistoryModal.content" :fields="statusHistoryModal.fields" :busy="statusHistoryModal.isBusy">
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'foo' ? '25%' : '25%' }"
            >
          </template>
          <template #table-busy>
            <div class="text-center text-warning my-1">
              <b-spinner class="align-middle"></b-spinner>
              <strong>A carregar...</strong>
            </div>
          </template>
          <template #cell(status)="row">
            <b-row style="padding: 0; margin: 0;"><div :style="'border: 1px solid;height: 15px;border-radius: 45px;background-color:' + row.item.color+ ';width: 15px;'"></div><b-col style="padding: 0; margin: 0;">{{row.item.status}}</b-col></b-row>
          </template>
        </b-table>
      </div>
      <div v-else>
        Ainda não existem interações.
      </div>
    </div>
  </b-modal>
  <b-modal :id="acceptCandidateModal.id" :title="acceptCandidateModal.title" ok-variant="success" ok-title="Sim" cancel-variant="secondary" cancel-title="Não" :ok-disabled="acceptCandidateModal.cmStartDate == '' || acceptCandidateModal.meetingDay == '' || acceptCandidateModal.meetingHours == '' || acceptCandidateModal.meetingMinutes == '' || acceptCandidateModal.meetingAddress == '' || acceptCandidateModal.meetingAddressDescription == ''" @hide="resetAcceptCandidateModal" @ok="acceptCandidate(acceptCandidateModal.content.id, acceptCandidateModal.meetingDay, acceptCandidateModal.meetingHours, acceptCandidateModal.meetingMinutes, acceptCandidateModal.meetingAddress, acceptCandidateModal.meetingAddressDescription, acceptCandidateModal.cmStartDate,)">
    <div class="text-center" v-if="saving">
      <b-spinner style="width: 10rem; height: 10rem; margin:20px" label="Large Spinner" variant="warning"></b-spinner>
      <p class="text-warning" style="font-size: 15px">A GRAVAR</p>
    </div>
    <div v-else>
      <b-row style="margin-left: 5px;color: gray">Data de Começo<p style="color: red;font-size: 16px">*</p></b-row>
      <b-form-datepicker
        id="cmStartDate"
        v-model="acceptCandidateModal.cmStartDate"
        size="sm"
        placeholder=""
        label-close-button="Fechar"
        :min="new Date()"
      ></b-form-datepicker>
      <b-row style="margin-left: 5px;color: gray">Data para Entrega do Material<p style="color: red;font-size: 16px">*</p></b-row>
      <b-form-datepicker
        id="meetingDay"
        v-model="acceptCandidateModal.meetingDay"
        size="sm"
        placeholder=""
        label-close-button="Fechar"
        :min="new Date()"
      ></b-form-datepicker>
      <b-row style="margin-left: 5px;color: gray">Hora para Entrega do Material<p style="color: red;font-size: 16px">*</p></b-row>
      <b-row style="width: 70%;">
        <b-col>
          <b-form-input
            id="meetingHours"
            v-model="acceptCandidateModal.meetingHours"
            type="number"
            min="00"
            max="23"
            style="text-align: right;"
          ></b-form-input>
        </b-col>
        <span style="font-weight: bold;font-size: 20px;">:</span>
        <b-col>
          <b-form-input
            id="meetingMinutes"
            v-model="acceptCandidateModal.meetingMinutes"
            type="number"
            min="00"
            max="59"
          ></b-form-input>
        </b-col>
      </b-row>
      <!-- <b-form-timepicker
        id="meetingHours"
        v-model="acceptCandidateModal.meetingHours"
        size="sm"
        placeholder=""
        label-close-button="Fechar"
      ></b-form-timepicker> -->
      <b-row style="margin-left: 5px;color: gray">Morada para Entrega do Material<p style="color: red;font-size: 16px">*</p></b-row>
      <b-form-input
        id="meetingAddress"
        v-model="acceptCandidateModal.meetingAddress"
        type="text"
      ></b-form-input>
      <b-row style="margin-left: 5px;color: gray">Descrição do Local<p style="color: red;font-size: 16px">*</p></b-row>
      <b-form-textarea
        id="meetingAddressDescription-text"
        v-model="acceptCandidateModal.meetingAddressDescription"
        placeholder="Junto a.. Perto de.."
        required
      ></b-form-textarea>
    </div>
  </b-modal>
  <b-modal :id="seeDocModal.id" modal-class="modalClass" scrollable size="xl" ok-only ok-variant="secondary" ok-title="Fechar" button-size="sm" @hide="resetSeeDocModal">
    <div>
      <div class="text-center" v-if="!seeDocModal.docRendered">
        <b-spinner style="width: 10rem; height: 10rem; margin:20px" label="Large Spinner" variant="warning"></b-spinner>
      </div>
      <div class="text-center" v-if="img != ''">
        <img :src="img" />
      </div>
      <vue-pdf-embed :source="pdf" @rendered="seeDocModal.docRendered = true" v-if="pdf != ''"/>
      <strong v-if="typeNotSuported">Tipo de ficheiro não suportado para visualização. Deverá transferir o ficheiro para o visualizar.</strong>
    </div>
  </b-modal>
</div>
</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import CandidatesOCDataService from "src/services/CandidatesOCDataService"
import LogCandidateStatusListingView from "src/pages/Candidates/LogCandidateStatus/LogCandidateStatusListingView.vue"
import LogCandidateStatusOCDataService from "src/services/LogCandidateStatusOCDataService"
import CandidateScheduleDataService from "src/services/CandidateScheduleDataService"
import CandidateManagementDocsDataService from "src/services/CandidateManagementDocsDataService"
export default {
  components: {
    VuePdfEmbed,
    LogCandidateStatusListingView
  },
  name: "CandidatesForm",
  props: {
    token: String,
    candidateId: String,
    isFormPage: Boolean
  },

  mounted () {
    this.loadInfo();
  },

  data() {
    return {
      loading: true,

      candidate: {},

      docExpireDay:'',
      docExpireMon:'',
      docExpireYear: '',

      observation: '',

      submitFilesModal: {
        id: 'submitFiles-modal',
        title: '',
        docType: '',
        candidateId: ''
      },
      files: '',

      candidateFiles: [],
      seeDocs: false,

      showSchedule: false,

      scheduleHeader: [
        {
          id: 0,
          header: "SEGUNDA"
        },
        {
          id: 1,
          header: "TERÇA"
        },
        {
          id: 2,
          header: "QUARTA"
        },
        {
          id: 3,
          header: "QUINTA"
        },
        {
          id: 4,
          header: "SEXTA"
        },
        {
          id: 5,
          header: "SÁBADO"
        },
        {
          id: 6,
          header: "DOMINGO"
        },
      ],
      candidateSchedule: null,
      candidateScheduleSaved: null,

      submitModal: {
        id: 'submit-modal',
        title: '',
      },
      isSigned: false,
      pdfRendered: false,

      moreInfoModal: {
        id: 'more-info-modal',
        title: '',
        content: ''
      },
      moreInfoObservations: '',

      statusHistoryModal: {
        id: 'status-history-modal',
        title: '',
        content: [],
        isBusy: true,
        fields: [
          {
            key: 'logDateTime',
            label: 'Data',
            sortable: true,
            tdClass: 'text-center', thClass: 'text-white'
          },
          {
            key: 'status',
            label: 'Estado',
            sortable: true,
            tdClass: 'text-center', thClass: 'text-white'
          },
          {
            key: 'observations',
            label: 'Observações',
            sortable: true,
            tdClass: 'text-center', thClass: 'text-white'
          },
          {
            key: 'user',
            label: 'Efetuada Por',
            sortable: true,
            tdClass: 'text-center', thClass: 'text-white'
          },
        ],
      },
      deleteCandidateModal: {
        id: 'deleteCandidate-modal',
        title: '',
        content: ''
      },
      acceptCandidateModal: {
        id: 'acceptCandidate-modal',
        title: '',
        content: '',
        meetingDay: '',
        meetingHours: '00',
        meetingMinutes: '00',
        meetingAddress: '',
        meetingAddressDescription: '',
        cmStartDate: ''
      },
      contractDoc: '',
      seeDocModal: {
        id: 'seeDoc-modal',
        title: '',
        docRendered: false
      },
      pdf: '',
      img: '',
      typeNotSuported: false,

      editBool: false,
      saving: false

    }
  },

  methods:{
    loadInfo(){
      if(this.token != undefined){
        this.getCandidateByToken(this.token)
      }
      else if(this.candidateId != undefined && this.candidateId != ''){
        this.getCandidateById(this.candidateId)
        this.getCandidateSchedule(this.candidateId)
      }
    },
    getCandidateByToken(token){
      CandidatesOCDataService.GetCandidateByToken({token: token}, {})
        .then(response => {
          this.candidate = response.data.result;
          this.candidateId = this.candidate.id;
          this.getCandidateFiles(this.candidateId);
          if(this.candidate.docExpireDate != null && this.candidate.docExpireDate != ''){
            this.docExpireDay = new Date(this.candidate.docExpireDate).getDate()
            this.docExpireMon = new Date(this.candidate.docExpireDate).getMonth() + 1
            this.docExpireYear = new Date(this.candidate.docExpireDate).getFullYear()
          };
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.$router.push({ path: '/candidates/form/formNotFound', query: { returnUrl: this.$route.path } })
        })
        .finally()
    },
    getCandidateById(candidateId){
      CandidatesOCDataService.GetCandidateById({id: candidateId}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => {
          this.candidate = response.data.result;
          this.getCandidateFiles(this.candidateId);
          if(this.candidate.docExpireDate != null && this.candidate.docExpireDate != ''){
            this.docExpireDay = new Date(this.candidate.docExpireDate).getDate()
            this.docExpireMon = new Date(this.candidate.docExpireDate).getMonth() + 1
            this.docExpireYear = new Date(this.candidate.docExpireDate).getFullYear()
          };
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    extendExpirationDate(candidateId){
      CandidatesOCDataService.ExtendExpirationDate({candidateId: candidateId}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Data extendida com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.loadInfo()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    saveCandidate(candidate, docExpireDay, docExpireMon, docExpireYear){
      if(docExpireYear != null && docExpireYear != '' && docExpireMon != null && docExpireMon != '' && docExpireDay != null && docExpireDay != ''){
        candidate.docExpireDate = new Date(docExpireYear, (docExpireMon-1), docExpireDay);
      }
      this.saving = true;
      CandidatesOCDataService.SaveCandidate(candidate, {})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Formulário gravado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.saving = false
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    submitCandidate(candidate, docExpireDay, docExpireMon, docExpireYear){
      if(docExpireYear != null && docExpireYear != '' && docExpireMon != null && docExpireMon != '' && docExpireDay != null && docExpireDay != ''){
        candidate.docExpireDate = new Date(docExpireYear, (docExpireMon-1), docExpireDay);
      }
      CandidatesOCDataService.SubmitCandidate(candidate, {})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Formulário Submetido com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.$router.push({ path: '/candidates/form/formSubmitted'})
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    expireCandidate(candidateId){
      CandidatesOCDataService.ExpireCandidate({candidateId: candidateId}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Acesso retirado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.loadInfo()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    askForAditionalInformation(candidateId, observations){
      CandidatesOCDataService.AskForAditionalInformation({candidateId: candidateId, observations: observations}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Formulário reenviado para o candidato!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.loadInfo()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    saveCandidateObs(candidate, docExpireDay, docExpireMon, docExpireYear){
      if(docExpireYear != null && docExpireYear != '' && docExpireMon != null && docExpireMon != '' && docExpireDay != null && docExpireDay != ''){
        candidate.docExpireDate = new Date(docExpireYear, (docExpireMon-1), docExpireDay);
      }
      this.saving = true;
      CandidatesOCDataService.SaveCandidateObs(candidate, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Formulário gravado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.saving = false
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    acceptCandidate(candidateId, meetingDay, meetingHours, meetingMinutes, meetingAddress, meetingAddressDescription, cmStartDate){
      localStorage.setItem('meetingAddressDefault', meetingAddress)
      localStorage.setItem('meetingAddressDescriptionDefault', meetingAddressDescription)
      this.loading = true;
      var mDay = this.getDateWithoutYearFormated(meetingDay);
      // var mHour = meetingHours.replace(":00", "");
      var mHour = ("0" + meetingHours).slice(-2) + ":" + ("0" + meetingMinutes).slice(-2)
      CandidatesOCDataService.AcceptCandidate({candidateId: candidateId, meetingDay: mDay, meetingHours: mHour, meetingAddress: meetingAddress, meetingAddressDescription: meetingAddressDescription, cmStartDate: cmStartDate}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Candidato aceite com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.loadInfo(), this.loading = false
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    rejectCandidate(candidateId){
      CandidatesOCDataService.RejectCandidate({candidateId: candidateId}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Candidato rejeitado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.loadInfo()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    arquiveCandidate(candidateId){
      CandidatesOCDataService.ArquiveCandidate({candidateId: candidateId}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Candidato arquivado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.loadInfo()
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    submitFilesInfo(candidateId, docType, button) {
        var title = "";
        switch(docType){
          case "CV":
            title = " Curriculo";
            break;
          case "IDDoc":
            title = " Documento de Identificação";
            break;
          case "NIFProof":
            title = " Comprovativo de NIF";
            break;
          case "AddressProof":
            title = " Comprovativo de Morada";
            break;
          case "BankProof":
            title = " Comprovativo de IBAN";
            break;
          case "DriversIdentification":
            title = " Carta de Condução";
            break;
          case "VehicleInsurance":
            title = " Comprovativo Seguro da Viatura";
            break;
          default:
            title = "";
            break;
        }
      this.submitFilesModal.title = 'Anexar'+ title
      this.submitFilesModal.docType = docType
      this.submitFilesModal.candidateId = candidateId
      this.$root.$emit('bv::show::modal', this.submitFilesModal.id, button)
    },
    resetSubmitFilesModal() {
      this.submitFilesModal.title = ''
      this.submitFilesModal.docType = ''
      this.submitFilesModal.candidateId = ''
      this.files = ''
    },
    submitModalInfo() {
      this.submitModal.title = ''
      this.getContract()
      this.$root.$emit('bv::show::modal', this.submitModal.id, "")
    },
    resetSubmitModal() {
      this.submitModal.title = ''
      this.isSigned = false
      this.pdfRendered = false
      this.contractDoc = ''
    },
    moreInfoModalInfo(candidateId) {
      this.moreInfoModal.title = 'Pedir mais Informação'
      this.moreInfoModal.content = candidateId
      this.$root.$emit('bv::show::modal', this.moreInfoModal.id, "")
    },
    resetMoreInfoModal() {
      this.moreInfoModal.title = ''
      this.moreInfoModal.content = ''
      this.moreInfoObservations = ''
    },
    statusHistoryModalInfo(candidateId) {
      this.statusHistoryModal.title = 'Histórico de Interações'
      this.getCandidateStatusHistory(candidateId)
      this.seeStatusHistory = true
      this.$root.$emit('bv::show::modal', this.statusHistoryModal.id, "")
    },
    resetStatusHistoryModal() {
      this.statusHistoryModal.title = ''
      this.statusHistoryModal.content = []
      this.statusHistoryModal.isBusy = true
      this.seeStatusHistory = false
    },



    formatCandidateStatusHistoryData(items){
      var candidateStatusHistoryData = [];
      items.forEach(element => {
        var status = "";
        var color = "";
        switch(element.status){
          case "PENDING":
            status = "Pendente";
            color = "#ffeb3b";
            break;
          case "ADITIONALINFOPENDING":
            status = "Devolvido";
            color = "#cddc39";
            break;
          case "SUBMITED":
            status = "Submetido";
            color = "#8bc34a";
            break;
          case "ACCEPTED":
            status = "Aceite";
            color = "#00e209";
            break;
          case "EXPIRED":
            status = "Expirado";
            color = "#ff9800";
            break;
          case "REJECTED":
            status = "Rejeitado";
            color = "#ff5722";
            break;
          case "DECLINED":
            status = "Recusou";
            color = "#59140f";
            break;
          default:
            status = element.status;
            color = "black";
            break;
        }
        candidateStatusHistoryData.push({
          element,
          id: element.id,
          status: status,
          color: color,
          observations: element.observations,
          user: element.user? element.user.userName : "",
          logDateTime: this.getDateWithHoursFormated(element.logDateTime),
        })
      })
      return candidateStatusHistoryData;
    },
    getCandidateStatusHistory(candidateId){
      LogCandidateStatusOCDataService.GetLogCandidateStatusByCandidateId({candidateId: candidateId} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.statusHistoryModal.isBusy=false , this.statusHistoryModal.content = this.formatCandidateStatusHistoryData(response.data.result)))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },



    handleFileUploads( event ){
      this.files = event.target.files;
    },
    submitFiles(candidateId, docType){
      let formData = new FormData();

      for( var i = 0; i < this.files.length; i++ ){
        let file = this.files[i];

        formData.append('files[' + i + ']', file);
      }

      formData.append('candidateId', candidateId);
      formData.append('docType', docType);

      CandidatesOCDataService.UploadFiles(formData, this.isFormPage? {} : {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(() => setTimeout(() => {  this.$notifications.notify(
          {
            message: `<span>Documento Adicionado com sucesso</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }),
          this.getCandidateFiles(candidateId) }, 1000))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique o tamanho do documento!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)

    },
    downloadFiles(id, filename){
      CandidatesOCDataService.DownloadFile({id: id}, this.isFormPage? {} : {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => {
              const blob = new Blob([response.data], { type: response.data.type })
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = filename
              link.click()
              URL.revokeObjectURL(link.href)
            })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)

    },
    getCandidateFiles(candidateId){
      CandidatesOCDataService.GetCandidateDocs({candidateId: candidateId}, this.isFormPage? {} : {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.candidateFiles=response.data.result, this.seeDocs = true))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    deleteCandidateFile(id, candidateId){
      CandidatesOCDataService.DeleteCandidateDoc({id: id}, this.isFormPage? {} : {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( setTimeout(() => {  this.$notifications.notify(
          {
            message: `<span>Documento Eliminado com sucesso</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }),
          this.getCandidateFiles(candidateId) }, 1000)))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique o tamanho do documento!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    deleteCandidateFileInfo(item) {
      this.deleteCandidateModal.title = 'Tem a certeza que pretende eliminar o ficheiro?'
      this.deleteCandidateModal.content = item
      this.$root.$emit('bv::show::modal', this.deleteCandidateModal.id, '')
    },
    resetDeleteCandidateModal() {
      this.deleteCandidateModal.title = ''
      this.deleteCandidateModal.content = ''
    },

    acceptCandidateInfo(item, docExpireDay, docExpireMon, docExpireYear) {
      this.saveCandidateObs(item, docExpireDay, docExpireMon, docExpireYear);
      this.acceptCandidateModal.title = 'Tem a certeza que pretende aceitar o candidato?'
      this.acceptCandidateModal.content = item
      this.acceptCandidateModal.meetingAddress = localStorage.getItem('meetingAddressDefault') ?? ''
      this.acceptCandidateModal.meetingAddressDescription = localStorage.getItem('meetingAddressDescriptionDefault') ?? ''
      this.$root.$emit('bv::show::modal', this.acceptCandidateModal.id, '')
    },
    resetAcceptCandidateModal() {
      this.acceptCandidateModal.title = ''
      this.acceptCandidateModal.content = ''
      this.acceptCandidateModal.meetingDay = '',
      this.acceptCandidateModal.meetingHours = '00',
      this.acceptCandidateModal.meetingMinutes = '00',
      this.acceptCandidateModal.meetingAddress = '',
      this.acceptCandidateModal.meetingAddressDescription = '',
      this.acceptCandidateModal.cmStartDate = ''
    },



    getCandidateSchedule(candidateId){
      CandidateScheduleDataService.GetCandidateSchedule({candidateId: candidateId}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.candidateSchedule=response.data.result, this.copyScheduleArray()))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    copyScheduleArray(){
      this.candidateScheduleSaved = JSON.parse(JSON.stringify(this.candidateSchedule))
    },
    updateCandidateSchedule(changes, candidateId){
      CandidateScheduleDataService.UpdateCandidateFullSchedule(changes, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => {
          this.getCandidateSchedule(candidateId)
          this.editBool = false
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    changeCandidateSchedule(newSchedule, schedule, candidateId){
      var changes = []
      schedule.forEach(s => {
          var n = newSchedule.find(d => d.id == s.id)
          if(s.state != n.state || s.observations != n.observations){
            changes.push(n)
          }
        })
      if(changes.length > 0){
        this.updateCandidateSchedule(changes, candidateId)
      }
      else{
        this.editBool = false
      }
    },


    getContract(){
      CandidateManagementDocsDataService.GetContractFileContent({},{})
        .then(response => ( this.contractDoc=response.data))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    seeDocModalInfo(docId, fileExtension) {
      this.seeDocModal.title = ''
      if(fileExtension == ".png" || fileExtension == ".jpg" || fileExtension == ".jpeg" || fileExtension == ".pdf"){
        this.getFile(docId, fileExtension)
      }
      else {
        this.typeNotSuported = true;
        this.seeDocModal.docRendered = true;
      }
      this.$root.$emit('bv::show::modal', this.seeDocModal.id, "")
    },
    resetSeeDocModal() {
      this.seeDocModal.title = ''
      this.pdf = ''
      if(this.img != ''){
        URL.revokeObjectURL(this.img)
      }
      this.img = ''
      this.seeDocModal.docRendered = false
      this.typeNotSuported = false
    },
    getFile(id, fileExtension){
      CandidatesOCDataService.DownloadFile({id: id}, this.isFormPage? {} : {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => {
          if(fileExtension == ".pdf"){
            this.pdf = response.data;
          }
          else{
            const blob = new Blob([response.data], { type: response.data.type })
            this.img = URL.createObjectURL(blob)
            this.seeDocModal.docRendered = true;
          }
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)

    },

    getDateWithHoursFormated(date){
      var dt = new Date(date)
      var str =
        dt.getDate().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) + "/" +
        (dt.getMonth()+1).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) + "/" + dt.getFullYear() + " " +
        dt.getHours().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) + ":" +
        dt.getMinutes().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
      return str
    },
    getDateWithoutYearFormated(date){
      var dt = new Date(date)
      var str =
        dt.getDate().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) + "/" +
        (dt.getMonth()+1).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
      return str
    }
  }
};
</script>

<style scoped>

.docsView{
  color: black;
}
.docsView:hover{
  color: #DAA520;
  cursor: pointer;
}

.absentIcon{
  color: black;
  display: inline !important;

}
.absentIcon:hover{
  color: #DAA520;

  cursor: pointer;
}

.addFileIcon{
  font-size: 1.0em;
  /* margin: 5px;
  margin-right: 10px; */
  color:lightgreen;
}
.addFileIcon:hover{
  color:green;
  cursor: pointer;
}

.removeFileIcon{
  font-size: 1.2em;
  margin: 5px;
  color:rgb(243, 39, 39);
}
.removeFileIcon:hover{
  color:red;
  cursor: pointer;
}

.transferFileIcon{
  font-size: 1.2em;
  margin: 5px;
  color:black;
}
.transferFileIcon:hover{
  color:#DAA520;
  cursor: pointer;
}

.page{
  margin: auto;
  background-color: white;
  padding:30px;
  padding-top: 0;
  padding-bottom: 0;
}

.textOverflow {
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 768px) {
    .page {
      padding:10px;
    }
    .textOverflow {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 90px;
    }
}

</style>
