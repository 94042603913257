<template>
  <div class="content" style="padding-top: 0px; background-color: #f7f7f7;">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="card-plain">
            <template slot="header">
              <h4 class="card-title">Configurações</h4>
            </template>
            <div>
              <b-button @click="activateShift0Job">Ativar Job Shift 0</b-button>
              <b-button @click="activateShift1Job">Ativar Job Shift 1</b-button>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import { BTable, BCard, BCardHeader, BCardImg, BCardGroup, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BFormCheckbox, BFormCheckboxGroup, BFormSelect, BPagination, BRow, BFormSelectOption    } from 'bootstrap-vue'
import SessionDataService from "src/services/SessionDataService"
import CandidatesOCDataService from "src/services/CandidatesOCDataService"
export default {
  components: {
    Card,
    BTable, BCard, BCardHeader, BCardImg, BCardGroup, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BFormCheckbox, BFormCheckboxGroup, BFormSelect, BPagination, BRow, BFormSelectOption
  },
  mounted () {
    this.getCurrentLoginInfo();
  },
  data() {
    return {
      currentUser:''
    };
  },
  methods: {
    getCurrentLoginInfo(){
      SessionDataService.GetCurrentLoginInformations({ headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => {
          (this.currentUser = response.data.result.user);
          if(this.currentUser == null){
            this.$router.push({ path: '/login', query: { returnUrl: this.$route.path } })
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    activateShift0Job(){
      CandidatesOCDataService.StartCheckDANotOnlineJobShift0({},{Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Job ativado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          })))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    activateShift1Job(){
      CandidatesOCDataService.StartCheckDANotOnlineJobShift1({},{Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Job ativado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          })))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    }
  }
};
</script>
<style>
</style>
