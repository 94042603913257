<template>
  <div>
    <div class="text-center">
      <b-spinner style="width: 10rem; height: 10rem; margin:20px" label="Large Spinner" variant="warning"></b-spinner>
    </div>
  </div>
</template>
<script>
import CandidatesOCDataService from "src/services/CandidatesOCDataService"
  export default {
    components: {
    },
    name: "CandidateFormDecliningPage",

    mounted () {
      if(this.$route.params.token != undefined){
        this.token = this.$route.params.token
        this.declineByCandidate(this.token)
      }
      else{
        this.$router.push({ path: '/candidates/form/formNotFound', query: { returnUrl: this.$route.path } })
      }
    },
    beforeRouteUpdate(to, from) {
      if(to.params.token != undefined){
        this.token = to.params.token
        this.declineByCandidate(this.token)
      }
      else{
        this.$router.push({ path: '/candidates/form/formNotFound', query: { returnUrl: this.$route.path } })
      }
    },

    data() {
      return {
        token: ''
      }
    },

    methods:{
      declineByCandidate(token){
        CandidatesOCDataService.DeclineByCandidate({token: token}, {})
          .then(response => {
            this.$router.push({ path: '/candidates/form/declined', query: { returnUrl: this.$route.path } })
          })
          .catch(error => {
            this.$router.push({ path: '/candidates/form/formNotFound', query: { returnUrl: this.$route.path } })
          })
          .finally(() => this.errored = false)
      }
    }
  };
  </script>

  <style scoped>
  </style>
